// [SellerUser]
// String id;
// DateTime createAt;
// String name;
// String phone;
// String address;
// String addressDetail;
// String businessNumber;
// String ownerName;
// String ownerPhone;
// String ownerEmail;
// String sellerOwnerName;
// String sellerOwnerPhone;
// String businessCertificateUrl;
// String taxEmail;
// List<dynamic> categorys;
// bool active;
// String accountType;
// String bankName;
// String accountNumber;
// String accountOwner;
// String settleBankName;
// String settleAccountNumber;
// String settleAccountUser;
// String whenToPay;
// bool manualPayment;
// String postCode;
// List<dynamic> sendedQuotationList;

// [QuotationRequest]
// String id;
// DateTime createAt;
// List<dynamic> categorys;
// String title;
// String content;
// String hospitalId;
// String hospitalName;
// String hospitalPhone;
// dynamic hospital;
// List<dynamic> imageUrls;
// List<dynamic> fileUrls;
// String hospitalWorkerId;
// dynamic hospitalWorker;
// String itemId;

// [Quotation]
// String id;
// DateTime createAt;
// List<dynamic> categorys;
// String title;
// String content;
// String hospitalId;
// String hospitalName;
// String hospitalPhone;
// String sellerId;
// dynamic seller;
// List<dynamic> imageUrls;
// List<dynamic> fileUrls;
// String quotationFileUrl;
// String quotationFileType;
// String quotationRequestId;
// dynamic quotationRequest;
// int priceSum;
// List<dynamic> quotationItems;
// String hospitalWorkerPhone;
// String itemId;
// bool hospitalRead;

// [QuotationItem]
// String id;
// DateTime createAt;
// String name;
// String spec;
// String manufacturer;
// bool priceHidden;
// String unit;
// int count;
// int price;
// String description;
// String sellerId;

// [Order]
// String id;
// DateTime createAt;
// String title;
// List<dynamic> categorys;
// String receiverName;
// String phone;
// String address;
// String payType;
// String cardCompany;
// String cardNumber;
// String cardApprovalNumber;
// DateTime paidAt;
// String paymentId;
// dynamic paymentResult;
// bool isRefunded;
// dynamic refundResult;
// String deliveryCompany;
// String deliveryNumber;
// DateTime deliveryArriveAt;
// String orderState;
// DateTime deliveryStartAt;
// DateTime deliveryDoneAt;
// String content;
// String sellerId;
// dynamic seller;
// String sellerPhone;
// String sellerEmail;
// String hospitalId;
// String hospitalName;
// String hospitalEmail;
// String hospitalPhone;
// String hospitalWorkerId;
// String hospitalWorkerName;
// String hospitalWorkerPhone;
// String hospitalWorkerEmail;
// String quotationRequestId;
// String quotationId;
// List<dynamic> quotationItems;
// int priceSum;
// String bankName;
// String accountNumber;
// String accountOwner;
// String payTimeType;
// String itemId;
// String whenToPay;
// String whenToPayBase;
// int whenToPayDay;

// [QuotationCustomerService]
// String id;
// DateTime createAt;
// String category;
// String title;
// String content;
// String hospitalId;
// String hospitalName;
// String hospitalPhone;
// String hospitalEmail;
// String quotationId;
// String orderId;
// List<dynamic> imageUrls;
// List<dynamic> fileUrls;
// String sellerId;
// String sellerName;
// String sellerPhone;
// String sellerEmail;
// String answer;
// bool answered;

/* eslint-disable no-unused-vars */
import Vue from "vue";
export default {};

function isNull(text) {
  if (typeof text == "undefined" || text == null) {
    return true;
  } else {
    return false;
  }
}

function toNumber(text) {
  if (isNull(text)) {
    return null;
  } else {
    return text * 1;
  }
}

function toText(number) {
  if (isNull(number)) {
    return null;
  } else {
    return number + "";
  }
}

function toSafeValue(value) {
  if (isNull(value)) {
    return null;
  } else {
    return value;
  }
}

export const models = {
  install(Vue) {
    Vue.prototype.$models = {
      SellerUserModel: {
        create: function () {
          return {
            id: "",
            createAt: null,
            name: "",
            phone: "",
            address: "",
            addressDetail: "",
            businessNumber: "",
            ownerName: "",
            ownerPhone: "",
            ownerEmail: "",
            sellerOwnerName: "",
            sellerOwnerPhone: "",
            businessCertificateUrl: "",
            taxEmail: "",
            categorys: null,
            active: null,
            accountType: "",
            bankName: "",
            accountNumber: "",
            accountOwner: "",
            settleBankName: "",
            settleAccountNumber: "",
            settleAccountUser: "",
            whenToPay: "",
            manualPayment: null,
            postCode: "",
            sendedQuotationList: null,
          };
        },
        set: function (model) {
          return {
            id: toText(model.id),
            createAt: toSafeValue(model.createAt),
            name: toText(model.name),
            phone: toText(model.phone),
            address: toText(model.address),
            addressDetail: toText(model.addressDetail),
            businessNumber: toText(model.businessNumber),
            ownerName: toText(model.ownerName),
            ownerPhone: toText(model.ownerPhone),
            ownerEmail: toText(model.ownerEmail),
            sellerOwnerName: toText(model.sellerOwnerName),
            sellerOwnerPhone: toText(model.sellerOwnerPhone),
            businessCertificateUrl: toText(model.businessCertificateUrl),
            taxEmail: toText(model.taxEmail),
            categorys: toSafeValue(model.categorys),
            active: toSafeValue(model.active),
            accountType: toText(model.accountType),
            bankName: toText(model.bankName),
            accountNumber: toText(model.accountNumber),
            accountOwner: toText(model.accountOwner),
            settleBankName: toText(model.settleBankName),
            settleAccountNumber: toText(model.settleAccountNumber),
            settleAccountUser: toText(model.settleAccountUser),
            whenToPay: toText(model.whenToPay),
            manualPayment: toSafeValue(model.manualPayment),
            postCode: toText(model.postCode),
            sendedQuotationList: toSafeValue(model.sendedQuotationList),
          };
        },
      },

      QuotationRequestModel: {
        create: function () {
          return {
            id: "",
            createAt: null,
            categorys: null,
            title: "",
            content: "",
            hospitalId: "",
            hospitalName: "",
            hospitalPhone: "",
            hospital: null,
            imageUrls: null,
            fileUrls: null,
            hospitalWorkerId: "",
            hospitalWorker: null,
            itemId: "",
          };
        },
        set: function (model) {
          return {
            id: toText(model.id),
            createAt: toSafeValue(model.createAt),
            categorys: toSafeValue(model.categorys),
            title: toText(model.title),
            content: toText(model.content),
            hospitalId: toText(model.hospitalId),
            hospitalName: toText(model.hospitalName),
            hospitalPhone: toText(model.hospitalPhone),
            hospital: toSafeValue(model.hospital),
            imageUrls: toSafeValue(model.imageUrls),
            fileUrls: toSafeValue(model.fileUrls),
            hospitalWorkerId: toText(model.hospitalWorkerId),
            hospitalWorker: toSafeValue(model.hospitalWorker),
            itemId: toText(model.itemId),
          };
        },
      },

      QuotationModel: {
        create: function () {
          return {
            id: "",
            createAt: null,
            categorys: null,
            deliveryCharge: 0,
            deliveryChargeType: "",
            title: "",
            content: "",
            hospitalId: "",
            hospitalName: "",
            hospitalPhone: "",
            sellerId: "",
            seller: null,
            imageUrls: null,
            fileUrls: null,
            quotationFileUrl: "",
            quotationFileType: "",
            quotationRequestId: "",
            quotationRequest: null,
            priceSum: 0,
            quotationItems: null,
            hospitalWorkerPhone: "",
            itemId: "",
            hospitalRead: null,
          };
        },
        set: function (model) {
          return {
            id: toText(model.id),
            createAt: toSafeValue(model.createAt),
            categorys: toSafeValue(model.categorys),
            deliveryCharge: toNumber(model.deliveryCharge),
            deliveryChargeType: toText(model.deliveryChargeType),
            title: toText(model.title),
            content: toText(model.content),
            hospitalId: toText(model.hospitalId),
            hospitalName: toText(model.hospitalName),
            hospitalPhone: toText(model.hospitalPhone),
            sellerId: toText(model.sellerId),
            seller: toSafeValue(model.seller),
            imageUrls: toSafeValue(model.imageUrls),
            fileUrls: toSafeValue(model.fileUrls),
            quotationFileUrl: toText(model.quotationFileUrl),
            quotationFileType: toText(model.quotationFileType),
            quotationRequestId: toText(model.quotationRequestId),
            quotationRequest: toSafeValue(model.quotationRequest),
            priceSum: toNumber(model.priceSum),
            quotationItems: toSafeValue(model.quotationItems),
            hospitalWorkerPhone: toText(model.hospitalWorkerPhone),
            itemId: toText(model.itemId),
            hospitalRead: toSafeValue(model.hospitalRead),
          };
        },
      },

      QuotationItemModel: {
        create: function () {
          return {
            id: "",
            createAt: null,
            name: "",
            spec: "",
            manufacturer: "",
            priceHidden: null,
            unit: "",
            minOrder: 1,
            count: 0,
            price: 0,
            description: "",
            sellerId: "",
            category: "",
            stock: 0,
          };
        },
        set: function (model) {
          return {
            id: toText(model.id),
            createAt: toSafeValue(model.createAt),
            name: toText(model.name),
            spec: toText(model.spec),
            manufacturer: toText(model.manufacturer),
            priceHidden: toSafeValue(model.priceHidden),
            unit: toText(model.unit),
            minOrder: toNumber(model.minOrder),
            count: toNumber(model.count),
            price: toNumber(model.price),
            description: toText(model.description),
            sellerId: toText(model.sellerId),
            category: toText(model.category),
            stock: toNumber(model.stock),
          };
        },
      },

      OrderModel: {
        create: function () {
          return {
            id: "",
            createAt: null,
            title: "",
            categorys: null,
            receiverName: "",
            phone: "",
            address: "",
            payType: "",
            cardCompany: "",
            cardNumber: "",
            cardApprovalNumber: "",
            paidAt: null,
            paymentId: "",
            paymentResult: null,
            isRefunded: null,
            refundResult: null,
            deliveryCompany: "",
            deliveryNumber: "",
            deliveryArriveAt: null,
            orderState: "",
            deliveryStartAt: null,
            deliveryDoneAt: null,
            deliveryInfo: [],
            content: "",
            sellerId: "",
            seller: null,
            sellerPhone: "",
            sellerEmail: "",
            hospitalId: "",
            hospitalName: "",
            hospitalEmail: "",
            hospitalPhone: "",
            hospitalWorkerId: "",
            hospitalWorkerName: "",
            hospitalWorkerPhone: "",
            hospitalWorkerEmail: "",
            quotationRequestId: "",
            quotationId: "",
            quotationItems: null,
            priceSum: 0,
            bankName: "",
            accountNumber: "",
            accountOwner: "",
            payTimeType: "",
            itemId: "",
            whenToPay: "",
            whenToPayBase: "",
            whenToPayDay: 0,
          };
        },
        set: function (model) {
          return {
            id: toText(model.id),
            createAt: toSafeValue(model.createAt),
            title: toText(model.title),
            categorys: toSafeValue(model.categorys),
            receiverName: toText(model.receiverName),
            phone: toText(model.phone),
            address: toText(model.address),
            payType: toText(model.payType),
            cardCompany: toText(model.cardCompany),
            cardNumber: toText(model.cardNumber),
            cardApprovalNumber: toText(model.cardApprovalNumber),
            paidAt: toSafeValue(model.paidAt),
            paymentId: toText(model.paymentId),
            paymentResult: toSafeValue(model.paymentResult),
            isRefunded: toSafeValue(model.isRefunded),
            refundResult: toSafeValue(model.refundResult),
            deliveryCompany: toText(model.deliveryCompany),
            deliveryNumber: toText(model.deliveryNumber),
            deliveryArriveAt: toSafeValue(model.deliveryArriveAt),
            deliveryInfo: toSafeValue(model.deliveryInfo),
            orderState: toText(model.orderState),
            deliveryStartAt: toSafeValue(model.deliveryStartAt),
            deliveryDoneAt: toSafeValue(model.deliveryDoneAt),
            content: toText(model.content),
            sellerId: toText(model.sellerId),
            seller: toSafeValue(model.seller),
            sellerPhone: toText(model.sellerPhone),
            sellerEmail: toText(model.sellerEmail),
            hospitalId: toText(model.hospitalId),
            hospitalName: toText(model.hospitalName),
            hospitalEmail: toText(model.hospitalEmail),
            hospitalPhone: toText(model.hospitalPhone),
            hospitalWorkerId: toText(model.hospitalWorkerId),
            hospitalWorkerName: toText(model.hospitalWorkerName),
            hospitalWorkerPhone: toText(model.hospitalWorkerPhone),
            hospitalWorkerEmail: toText(model.hospitalWorkerEmail),
            quotationRequestId: toText(model.quotationRequestId),
            quotationId: toText(model.quotationId),
            quotationItems: toSafeValue(model.quotationItems),
            priceSum: toNumber(model.priceSum),
            bankName: toText(model.bankName),
            accountNumber: toText(model.accountNumber),
            accountOwner: toText(model.accountOwner),
            payTimeType: toText(model.payTimeType),
            itemId: toText(model.itemId),
            whenToPay: toText(model.whenToPay),
            whenToPayBase: toText(model.whenToPayBase),
            whenToPayDay: toNumber(model.whenToPayDay),
          };
        },
      },

      QuotationCustomerServiceModel: {
        create: function () {
          return {
            id: "",
            createAt: null,
            category: "",
            title: "",
            content: "",
            hospitalId: "",
            hospitalName: "",
            hospitalPhone: "",
            hospitalEmail: "",
            quotationId: "",
            orderId: "",
            imageUrls: null,
            fileUrls: null,
            sellerId: "",
            sellerName: "",
            sellerPhone: "",
            sellerEmail: "",
            answer: "",
            answered: null,
          };
        },
        set: function (model) {
          return {
            id: toText(model.id),
            createAt: toSafeValue(model.createAt),
            category: toText(model.category),
            title: toText(model.title),
            content: toText(model.content),
            hospitalId: toText(model.hospitalId),
            hospitalName: toText(model.hospitalName),
            hospitalPhone: toText(model.hospitalPhone),
            hospitalEmail: toText(model.hospitalEmail),
            quotationId: toText(model.quotationId),
            orderId: toText(model.orderId),
            imageUrls: toSafeValue(model.imageUrls),
            fileUrls: toSafeValue(model.fileUrls),
            sellerId: toText(model.sellerId),
            sellerName: toText(model.sellerName),
            sellerPhone: toText(model.sellerPhone),
            sellerEmail: toText(model.sellerEmail),
            answer: toText(model.answer),
            answered: toSafeValue(model.answered),
          };
        },
      },
    };
  },
};

Vue.use(models);
